

const Preloader = () => {

  

  

  return (
    <div id="preloader" className="h-screen w-screen bg-white z-40 fixed overflow-hidden flex justify-center items-center">
    
 	<span className='sr-only'>Loading...</span>
  	<div className='h-8 w-8 mr-5 bg-black rounded-full animate-bounce [animation-delay:-0.3s]'></div>
	<div className='h-8 w-8 mr-5 bg-black rounded-full animate-bounce [animation-delay:-0.15s]'></div>
	<div className='h-8 w-8 mr-5 bg-black rounded-full animate-bounce'></div>
</div>
  )
 
}

export default Preloader