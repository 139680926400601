import { Arrowsubmit, Footerlogo, Mailsent } from "../utils"
import { services, socialIcons } from "../constants"
import emailjs from '@emailjs/browser'
import gsap from 'gsap'
import { useRef, useState } from "react";
const Footer = () => {
    const currentYear = new Date().getFullYear();
    const  [icon, setIcon] = useState(Arrowsubmit)
    const form = useRef(null);
    const emailSubmit = (e) =>{
        e.preventDefault();
        emailjs
      .sendForm('service_46pz3qb', 'template_0elcxai', form.current, {
        publicKey: 'LsSwun_hQbRXKdQjI',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          gsap.to(".submit-icon", {
            rotate: 360,
            scale: 1.2,
            duration: 0.6,
            onComplete: () => {
              // After animation, change the icon
              setIcon(Mailsent); 
              
            }
          });
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
    }
  return (
   <footer className="h-screen flex gap-32 mt-32 flex-col mb-12">
    <section className="place-self-center max-sm:w-full mt-auto justify-self-center h-100 justify-center">
        <div className="max-w-[min-content] max-sm:max-w-full max-sm:flex max-sm:gap-8 flex-col ">
            <p className="text-h2 max-sm:text-center max-sm:w-full w-[90%]">Your Business’s next <strong>Big Step</strong>  starts with a 
          simple <strong>hello!</strong> </p>
          <h2 className="text-xlg max-sm:text-center max-sm:text-wrap max-sm:leading-none text-nowrap tracking-wide font-black -my-5">
          Lets Talk
           </h2>
          <form ref={form} onSubmit={emailSubmit}>
            <div className="bg-black max-sm:w-9/12 max-sm:mx-auto flex justify-between rounded-full px-[15px] py-[15px] w-7/12 ml-auto"> 
            <input
          type="email"
          name="message"
          required
          placeholder="name@email.com"
          autoComplete="off"
          className="flex-grow h-full px-4 text-p focus:bg-transparent focus:text-white text-white bg-transparent outline-none"
          aria-label="Enter your email address"
        />
        <button type="submit" >
            <img src={icon} className="hover:scale-110 submit-icon hover:rotate-[360deg] w-[25px] h-[25px] duration-700 transition-all" alt="" />
        </button>
            </div>
           </form>
        </div>
    </section>
    <section className="gap-40 flex flex-col">
        
        <div className=" w-9/12 max-sm:w-full flex-wrap max-sm:justify-center max-md:gap-8 max-sm:gap-8 flex justify-between mt-auto mx-auto mb-14">
            {services.map((service)=>(
                <span key={service.title} className="text-h2 uppercase max-sm:text-h2mob">{service.title}</span>
            ))} 
     </div>

     <div className="flex mb-6 flex-row justify-between">
        <div className="flex  flex-col flex-grow gap-3">
        <div className="flex gap-3">
            {socialIcons.map((socialIcon, index)=>(
                <a className=" inline-block justify-center bg-black hover:opacity-95 transition-all duration-500 hover:scale-110 rounded-full p-3" key={index} href={socialIcon.url}>
                   <socialIcon.icon className="text-white w-6 h-6 max-sm:w-4 max-sm:h-4"/>
                </a>
            )
            )}
          
            
        </div>
        <hr className="w-full border-t border-black" />
        <p className="text-p text-black">{currentYear} © All Rights Reserved</p>

         </div>
         <div className="ml-8 flex items-center">
        <img className="max-sm:w-24 " src={Footerlogo} alt="" />
        </div>



     </div>
    



    </section>
    

   </footer>
  )
}

export default Footer