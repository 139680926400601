import { forwardRef, useEffect, useState } from "react"
import { workCont } from "../constants";
import { Arrowvm } from "../utils";





const Works = forwardRef((props, ref) => {
  const startIndex = 0
  const [endIndex, setEndIndex] = useState(3)
  const [visibileItems, setVisibleItems] = useState([])
  const totalItems = workCont.length
  console.log(workCont.length)
 
  useEffect(() => {
    const newItems = workCont.slice(startIndex, endIndex);
    
    setVisibleItems(prevItems => {
      const updatedItems = [...prevItems];
  
      newItems.forEach(item => {
        if (!updatedItems.find(i => i.id === item.id)) {
          updatedItems.push({ ...item, isVisible: false });
        }
      });
      
      return updatedItems;
    });
  
    const timer = setTimeout(() => {
      setVisibleItems(items => {
        return items.map(item => ({ ...item, isVisible: true }));
      });
    }, 250);
  
    return () => clearTimeout(timer);
  
  }, [endIndex]); 
  
  
    return (
      <section className=" max-w-[1920px] pt-10" ref={ref}>
       <div className="sectionContentP">
 <div className="flex items-center flex-row mb-3"><h2 className="text-h2 whitespace-nowrap">My Works</h2><hr className="w-screen border-t ml-4 -mr-16 max-sm:-mr-5 border-black" /></div>
        <div className="mx-auto items-center flex flex-col">
          <div className="grid grid-cols-3 max-lg:grid-cols-1 gap-4">
            {visibileItems.map((item)=>(
              <div key={item.id} className={`relative flex justify-center opacity-0 ${item.isVisible?'opacity-100':''} group transition-all duration-700  min-h-[360px] max-md:min-h-[250px] overflow-hidden h-full max-md:rounded-md rounded-lg shadow-black/20 shadow-sm`}>
                <img src={item.image} 
                alt={item.title}
                width='100%'
                height={600}
                className=" bg-black h-full object-cover"
                />
                <div className="absolute w-[90%] h-16 min-w-[min-content] max-md:flex-col max-md:items-start overflow-visible group-hover:whitespace-normal whitespace-nowrap max-sm:h-16 max-sm:justify-center px-4 max-sm:hover:h-min group max-sm:rounded-b-md max-sm:rounded-t-none max-sm:group-hover:items-start max-sm:group-hover:justify-center max-sm:flex max-sm:w-full max-sm:py-0 max-sm:px-3 max-sm:m-0  group-hover:w-full group-hover:h-full flex-row flex justify-between items-center hover:flex-center group-hover:my-0 hover:rounded-none group-hover:mx-0 transition-all duration-700 backdrop-blur-md bottom-0  my-6 rounded-2xl  py-2 bg-[#000000]/80 group-hover:flex-center group-hover:justify-center">
               
                <h3 className="text-white/100 group-hover:duration-0 group-hover:absolute  group-hover:opacity-0 duration-[2000ms] max-sm:text-h2mob  text-h3 ">{item.title}</h3>
                <p className="text-white/90 group-hover:opacity-0 group-hover:absolute opacity-100 group-hover:duration-0 duration-[2000ms] max-sm:text-ps truncate uppercase text-p">{item.subtitle}</p>
                <div className="group-hover:opacity-100 max-sm:items-start transition-opacity duration-100 group-hover:duration-[3000ms] absolute group-hover:static flex-col items-center flex  w-[80%] max-sm:w-[90%] opacity-0">
                     <p className="text-white/90   max-sm:text-ps truncate uppercase text-p">{item.subtitle}</p>
                    <h2 className="text-white/100  max-sm:text-h2mob  text-h2 ">{item.title}</h2>
                    <p className="text-white/100 max-sm:text-left whitespace-normal text-center max-sm:text-ps mt-3 opacity-0 group-hover:opacity-100 duration-[3s] group-hover:flow-root text-p">{item.description}</p>
                    <a className="text-white  underline   group-hover:flow-root text-p  max-sm:text-ps  mt-8" href={`/${item.title.replace(/\s+/g, '').toLowerCase()}`}>View Project</a>
                </div>
               
             </div>
                
              </div>
            ))}
             
          </div>
          <button disabled={endIndex >= totalItems} className="px-4 flex flex-row gap-4  py-2 mt-10 p border  border-black rounded-[30px] hover:bg-black hover:text-white transition group duration-700" onClick={()=>setEndIndex((prev)=>Math.min(prev+3, totalItems))}>{endIndex>=totalItems?"That's It!" : "View More"} <img className="group-disabled:hidden group-hover:invert transition w-[25px] h-[25px] duration-700" src={Arrowvm} alt="" /> </button>
        </div>

       </div>
      </section>
    );
  });

Works.displayName = "Works"

export default Works