
import { useEffect } from "react"
import { Sitelogo } from "../utils"
import gsap from 'gsap'
const Navbar = () => {

  useEffect(()=>{
    if(document.readyState !== "loading"){
      gsap.to(['#navBar','#navBtn'],{y:0, duration:1, delay:1, ease:"power3.out"})
    }
  })

  return (
    <header><nav id="navBar" className="h-24 flex -translate-y-80  bg-white justify-between items-center">
      <img  className="w-[70px]" src={Sitelogo} alt="" />
      <button className="pr-8 pl-8 pt-2 pb-2 border border-black rounded-[30px] hover:bg-black hover:text-white transition duration-700" onClick={alert.hey}>Lets Talk</button>
    </nav></header>
    
  )
}

export default Navbar